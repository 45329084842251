import React, { useEffect, useState } from 'react';
import { ConfigProvider, Input, notification, theme } from 'antd';
import { Header } from '../../components/header';
import {
  validateEmailFormat,
  validateEmailIsUnique,
} from '../../shared/validation';
import { forgotPassword } from '../../actions/auth';
import { useNavigate } from 'react-router-dom';
import { openNotificationWithIcon } from '../../components/notifications';
import { Paths } from '../../routes/paths';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [email, setEmail] = useState('');
  const [sentEmail, setSentEmail] = useState(false);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <section className="forgot-password outer-wrapper">
        {contextHolder}
        <Header title="Forgot Password" />
        <div className="center-wrapper">
          <div className="inner-wrapper">
            <div className="top-wrapper">
              <h2
                onClick={() => {
                  openNotificationWithIcon({
                    api,
                    type: 'success',
                    message: 'Sent Email',
                    description:
                      "We've sent you an email with a link to reset your password.",
                  });
                }}
              >
                Forgot Password
              </h2>
            </div>
            <div className="middle-wrapper">
              <Input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
              />
            </div>
            <div className="bottom-wrapper">
              {!sentEmail ? (
                <button
                  className="action-button call-to-action"
                  onClick={async () => {
                    const isEmailValid = validateEmailFormat(email);
                    const isEmailUnique = await validateEmailIsUnique(email);

                    if (isEmailValid && !isEmailUnique) {
                      await forgotPassword(email);
                      setSentEmail(true);
                      openNotificationWithIcon({
                        api,
                        type: 'success',
                        message: 'Sent Email',
                        description:
                          "We've sent you an email with a link to reset your password.",
                      });
                    } else {
                      openNotificationWithIcon({
                        api,
                        type: 'error',
                        message: 'Invalid Email',
                        description:
                          "Your email is not valid. Either it is not in the correct format or it's not in our system.",
                      });
                    }
                  }}
                >
                  Send Email
                </button>
              ) : (
                <div className="email-sent">
                  <button
                    className="action-button call-to-action"
                    onClick={() => {
                      navigate(Paths.login);
                    }}
                  >
                    Login
                  </button>
                  <p className="card-default">
                    Didn&apos;t receive the link?{' '}
                    <a
                      onClick={async () => {
                        await forgotPassword(email);
                        openNotificationWithIcon({
                          api,
                          type: 'success',
                          message: 'Sent Email',
                          description:
                            "We've sent you an email with a link to reset your password.",
                        });
                      }}
                    >
                      Resend
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
};
